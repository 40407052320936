





















interface ComponentData {
  index: number
}
import Vue from 'vue'

export default Vue.extend({
  data(): ComponentData {
    return {
      index: 0
    }
  },
  props: {
    data: {
      type: Array
    }
  },
  methods: {
    valueChange(newValue: any): any {
      this.$emit('change', newValue.name)
    }
  }
})
