export class UiState {
  state: string
  data: any

  constructor(
    private readonly states: string[],
    private readonly defaultState?: string
  ) {
    this.state = this.defaultState || states[0]
    this.data = null
  }

  set(state: string, data?: any) {
    if (this.states.includes(state)) {
      this.state = state
      this.data = data
    }
  }

  is(state: string): boolean {
    return this.state === state
  }

  in(states: string[]): boolean {
    return states.includes(this.state)
  }

  reset() {
    this.state = this.defaultState || this.states[0]
    this.data = null
  }
}

export default function uiState(states: string[], defaultState?: string) {
  return new UiState(states, defaultState)
}
