




















































































































































import Vue, { VueConstructor } from 'vue'

import Switcher from '@/components/Switcher.vue'
import OtpModal from '@/components/OtpModal.vue'

import uiState from '../../../../ui-state'
import Account, { AccountType } from '../../../../store/models/Account'
import { mapGetters } from 'vuex'

interface ComponentData {
  actionType: Array<Record<string, any>>
  amount: number | null
  otm: string
  showOtpModal: boolean
  file: any
  fileName: string
  isDeposit: boolean
  uiState: any
  account: string
  showSuccessModal: boolean
}

interface AccountDataInterface {
  accountKey: string
  accountName: string | undefined
  amount: number | null
  productId: string | undefined
  otm: string
  otp?: string
  file?: File
}
export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      otpModal: InstanceType<typeof OtpModal>
      formValidator: Vue & { validate: () => Promise<any> }
    }
  }
>).extend({
  components: {
    Switcher,
    OtpModal
  },
  data(): ComponentData {
    return {
      actionType: [
        {
          title: 'Dépôt',
          name: 'deposit'
        },
        {
          title: 'Retrait',
          name: 'retrait'
        }
      ],
      amount: null,
      otm: 'mtn',
      showOtpModal: false,
      file: null,
      fileName: '',
      isDeposit: true,
      uiState: uiState([
        'validating',
        'validating_otp',
        'error_request_otp',
        'error_no_otp_user',
        'error_already_validated',
        'error_low_balance',
        'created'
      ]),
      account: '',
      showSuccessModal: false
    }
  },

  computed: {
    ...mapGetters('product', ['getCurrentProduct']),
    ...mapGetters('auth', ['user']),
    accounts(): Array<Account> {
      if (this.isDeposit) {
        return Account.query()
          .where((value: any) => {
            this.formatAccountNamefrench(value)
            return (
              value.otm == this.otm &&
              (value.type == AccountType.DISBURSEMENT ||
                value.type == AccountType.FEES) &&
              value.owner == this.user.organizationId &&
              value?.productId == this.getCurrentProduct.id
            )
          })
          .get()
      } else {
        return Account.query()
          .where((value: any) => {
            this.formatAccountNamefrench(value)
            return (
              value.otm == this.otm &&
              value.productId == this.getCurrentProduct.id &&
              ((value.type == AccountType.REFUND &&
                value.owner == this.user.organizationId) ||
                value.type == AccountType.INTEREST)
            )
          })
          .get()
      }
    },
    validating(): boolean {
      return this.uiState.is('validating')
    },
    verifyAmount(): any {
      return {
        numeric: true,
        minValue: 1
      }
    }
  },

  methods: {
    formatAccountNamefrench(account: any) {
      const accountTypeEquiv: any = {
        disbursement: 'Décaissement',
        fees: 'Frais',
        refund: 'Remboursement',
        interest: 'Intérêt'
      }
      account.frenchName = account.name
      account.frenchName = account.frenchName.replace(/_/g, ' ')
      account.frenchName = account.frenchName.replace(
        account.type.toUpperCase(),
        accountTypeEquiv[`${account.type}`]
      )
    },
    onFileChanged($event: any): any {
      this.file = $event.target.files[0]
      if (this.file) this.fileName = this.file.name
    },
    requestChange(value: string): any {
      this.isDeposit = value == 'deposit' ? true : false
      this.account = ''

      console.log('request value', value)
    },
    validate(otp?: string): any {
      this.showOtpModal = false

      console.log('this is otp', otp)

      this.$refs.formValidator.validate().then((isValid: boolean) => {
        if (isValid) {
          this.$emit('successMessage', '')
          this.$emit('errorMessage', '')
          this.showOtpModal = true
          if (!otp) {
            this.uiState.set('validating_otp')
          } else this.uiState.set('validating')

          const data: AccountDataInterface = {
            accountKey: this.account,
            accountName: this.accounts.find(
              (value: any) => value.key == this.account
            )?.name,
            amount: this.amount,
            productId: this.getCurrentProduct.id,
            otm: this.otm,
            otp: otp
          }

          if (this.isDeposit)
            Account.api()
              .creditDistributionAccount(data)
              .then((value: any) => {
                this.showSuccessModal = true
                this.showOtpModal = false
                this.uiState.set('created', 'Transaction créer')
                this.$emit(
                  'successMessage',
                  'Votre transaction a été effectuée avec succès'
                )
              })
              .catch((error: any) => {
                if (error.response) {
                  switch (error.response.status) {
                    case 400:
                      if (error.response.data.code === 'no_otp_user') {
                        this.uiState.set('error_no_otp_user')
                      } else if (error.response.data.code === 'otp_invalid') {
                        this.$refs.otpModal.setError(
                          'Le code otp entré est invalide'
                        )
                        this.uiState.set('error_request_otp')
                      }
                      break
                    case 403:
                      if (
                        error.response.data.code === 'need_confirmation_otp'
                      ) {
                        this.uiState.set('error_request_otp')
                      } else if (
                        error.response.data.code === 'already_validated'
                      ) {
                        this.uiState.set(
                          'error_already_validated',
                          error.response.data.validatedBy
                        )
                      }
                      break
                    case 406:
                      this.showOtpModal = false
                      this.uiState.set(
                        'error_low_balance',
                        'Le solde de votre compte est insuffisant'
                      )
                      if (
                        error.response.data.code ===
                        'reserve_balance_insufficient'
                      ) {
                        this.$emit(
                          'errorMessage',
                          'Le solde de votre compte est insuffisant'
                        )
                      }
                      break
                    default:
                      this.showOtpModal = false
                      this.$emit(
                        'errorMessage',
                        'Echec de votre requète, veuillez reéssayer'
                      )
                  }
                }
              })
          else {
            data['file'] = this.file
            Account.api()
              .withdrawlRefundAccount(data)
              .then((value: any) => {
                this.showSuccessModal = true
                this.showOtpModal = false
                this.uiState.set('created', 'Transaction créer')
                this.$emit(
                  'successMessage',
                  'Votre transaction a été effectuée avec succès'
                )
              })
              .catch((error: any) => {
                if (error.response) {
                  switch (error.response.status) {
                    case 400:
                      if (error.response.data.code === 'no_otp_user') {
                        this.uiState.set('error_no_otp_user')
                      } else if (error.response.data.code === 'otp_invalid') {
                        this.$refs.otpModal.setError(
                          'Le code otp entré est invalide'
                        )
                        this.uiState.set('error_request_otp')
                      }
                      break
                    case 403:
                      if (
                        error.response.data.code === 'need_confirmation_otp'
                      ) {
                        this.uiState.set('error_request_otp')
                      } else if (
                        error.response.data.code === 'already_validated'
                      ) {
                        this.uiState.set(
                          'error_already_validated',
                          error.response.data.validatedBy
                        )
                        this.showOtpModal = false
                      }
                      break
                    case 406:
                      this.showOtpModal = false
                      if (
                        error.response.data.code ===
                        'withdrawlAccount_balance_insufficient'
                      ) {
                        this.uiState.set(
                          'error_low_balance',
                          error.response.data.validatedBy
                        )
                        this.$emit(
                          'errorMessage',
                          "Le compte selectionné ne dispose pas d'assez de fond pour ce retrait"
                        )
                      }
                      break
                    default:
                      this.showOtpModal = false
                      this.$emit(
                        'errorMessage',
                        'Echec de votre requète, veuillez reéssayer'
                      )
                  }
                }
              })
          }
        }
      })
    },
    resetField(): any {
      this.amount = null
      this.account = ''
      this.otm = 'mtn'
    }
  },

  mounted() {
    Account.api().fetchAll()
  }
})
