


























import Vue from 'vue'

export default Vue.extend({
  props: {
    active: Boolean,
    loading: Boolean,
    message: {
      type: String,
      default:
        "Nous avons envoyer un code par SMS à l'administrateur. Veuillez le saisir pour continuer"
    }
  },

  data() {
    return {
      model: this.active,
      otp: ''
    }
  },

  watch: {
    active(isActive) {
      if (this.model !== isActive) {
        this.model = isActive
      }
    },

    model() {
      this.$emit('update:active', this.model)
    }
  },

  methods: {
    setError(this: any, error: string) {
      this.$refs.validator.setErrors({
        otp: [error]
      })
    },

    onSubmit(this: any) {
      this.$refs.validator.validate().then((isValid: boolean) => {
        if (isValid) {
          this.$emit('submit', this.otp)
        }
      })
    }
  }
})
